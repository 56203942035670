import { useCallback, useContext, useEffect, useState } from "react";
import Swal from "sweetalert2";
import { Icons } from "components/Icons";
import { SubHeader } from "components/SubHeader";
import { ViewLoader } from "components/ViewLoader";
import { api } from "services/api";
import { getColor, convertDateToString } from "utils/globalFunctions";
import { necropsyConfig, slaughterConfig } from "utils/globalData";
import UpdateList from "contexts/updateList";
import { Broiler } from "./Connect/Broiler";
import readXlsxFile from "read-excel-file";
import ProfileContext from "contexts/profile";
import { Contact } from "./Contact";
import { HeavyBroiler } from "./Connect/HeavyBroiler";
import { Button } from "components/Button";
import { Slaughter } from "./Connect/Slaughter";
import { StepButtons } from "./Connect/StepButtons";
import { StepButtonsMegg } from "./Megg/StepButtons";
import { Image } from "components/Image";
import { Production } from "./Megg/Production";
import { CreateRecreate } from "./Megg/CreateRecreate";

export function Customer(props) {
  const { setUpdateList, updateList } = useContext(UpdateList);

  const { roles } = useContext(ProfileContext);

  const [loading, setLoading] = useState(true);

  const [customer, setCustomer] = useState({
    code: "",
    name: "",
    phoneNumber: "",
    state: "",
    city: "",
    cnpj: "",
    _id: "",
    nutrition: "",
    purchaseMotivation: "",
    port: "",
    channel: "",
    customerRating: "",
    contact: [],
  });

  const [getCustomer, setGetCustomer] = useState(true);

  const [historicBroiler, setHistoricBroiler] = useState([]);

  const [historicMegg, setHistoricMegg] = useState([]);

  const [historicHeavyBroiler, setHistoricHeavyBroiler] = useState([]);

  const [graphLastMonth, setGraphLastMonth] = useState({});

  const [graphLastMonthSlaughter, setGraphLastMonthSlaughter] = useState({});

  const [consolidated, setConsolidated] = useState([]);

  const [consolidatedSlaughter, setConsolidatedSlaughter] = useState([]);

  const [lastNecropsies, setLastNecropsies] = useState([]);

  const [lastSlaughters, setLastSlaughters] = useState([]);

  const [lastChecklistSlaughters, setLastChecklistSlaughters] = useState([]);

  const [lastPreNecropsies, setLastPreNecropsies] = useState([]);

  const [randomCore, setRandomCore] = useState([]);

  const [consolidatedInput, setConsolidatedInput] = useState({
    files: [],
    fileName: "",
    value: "",
  });

  const [consolidatedSlaughterInput, setConsolidatedSlaughterInput] = useState({
    files: [],
    fileName: "",
    value: "",
  });

  const [uploading, setUploading] = useState(false);

  const getReportData = useCallback(() => {
    const response = {
      datasets: [
        {
          label: "Score-Connect (Digestivo)",
          type: "line",
          data: graphLastMonth.perPeriod.map((item) => item.totalScore),
          fill: false,
          borderColor: getColor("#007bff", 0.7),
          backgroundColor: getColor("#007bff", 0.7),
          pointBorderColor: "#007bff",
          pointBackgroundColor: "#007bff",
          pointHoverBackgroundColor: getColor("#007bff", 0.9),
          pointHoverBorderColor: getColor("#007bff", 0.9),
          yAxisID: "y-axis-1",
        },
      ],
    };

    Object.keys(graphLastMonth.initialTotalPerScore).forEach((key) => {
      const injuryType = "digestive";
      const stack = key.split("-")[0];
      const config = necropsyConfig[injuryType][key.split("-")[0]];

      response.datasets.push({
        label: `${config.label}, score:${key.split("-")[1]}`,
        yAxisID: "y-axis-2",
        type: "bar",
        backgroundColor: graphLastMonth.perPeriod.map((item) => {
          return getColor(config.color, 0.9 - +`0.${+key.split("-")[1]}`);
        }),
        borderColor: graphLastMonth.perPeriod.map((item) => {
          return getColor(config.color, 0.8);
        }),
        hoverBackgroundColor: graphLastMonth.perPeriod.map((item) => {
          return getColor(config.color, 1 - +`0.${+key.split("-")[1]}`);
        }),
        hoverBorderColor: graphLastMonth.perPeriod.map((item) => {
          return getColor(config.color, 1);
        }),
        borderWidth: 1,
        data: graphLastMonth.perPeriod.map((item) => {
          return item.totalBirds
            ? ((item[key] / item.totalBirds) * 100).toFixed(0)
            : 0;
        }),
        stack,
      });
    });

    return response;
  }, [graphLastMonth]);

  const getReportlabels = useCallback(
    () =>
      graphLastMonth.perPeriod.map((item) => {
        return [
          `Semana ${item.week} / ${item.year} `,
          `De ${convertDateToString(
            item.startOfWeek
          )} até ${convertDateToString(item.endOfWeek)}`,
          `${item.totalBirds} Aves Necropsiadas`,
          graphLastMonth.createdBy ? graphLastMonth.createdBy : "",
        ];
      }),
    [graphLastMonth]
  );

  const getReportDataSlaughter = useCallback(() => {
    const response = {
      datasets: [
        {
          label: "Score-Abate",
          type: "line",
          data: graphLastMonthSlaughter.data.map((item) => item.totalScore),
          fill: false,
          borderColor: getColor("#007bff", 0.7),
          backgroundColor: getColor("#007bff", 0.7),
          pointBorderColor: "#007bff",
          pointBackgroundColor: "#007bff",
          pointHoverBackgroundColor: getColor("#007bff", 0.9),
          pointHoverBorderColor: getColor("#007bff", 0.9),
          yAxisID: "y-axis-1",
        },
      ],
    };
    const keys = [
      "ventralInspection.skinBreakage",
      "ventralInspection.chestSkinBurn",
      "ventralInspection.celluliteBelowChest",
      "ventralInspection.chestSkinBleeding",
      "ventralInspection.bleedingWings",
      "ventralInspection.displacementWings",
      "ventralInspection.fractureWings",
      "dorsalInspection.tailSkinBreakage",
      "dorsalInspection.dermatitis",
      "dorsalInspection.scratchedWoundNew",
      "dorsalInspection.scratchedWoundOld",
      "dorsalInspection.bleedingThighs",
      "dorsalInspection.thighFracture",
      "dorsalInspection.dorsalMyopathy",
      "internalInspection.punctateChestHemorrhage",
      "internalInspection.thighsPunctiformHemorrhage",
      "internalInspection.whiteStreak",
      "internalInspection.woodChest",
      "internalInspection.chestSpaghetti",
      "legCorns",
    ];
    keys.forEach((key) => {
      const arrayKeys = key.split(".");
      const itemConfig =
        key === "legCorns"
          ? slaughterConfig[key]
          : slaughterConfig[arrayKeys[0]][arrayKeys[1]];
      response.datasets.push({
        label: itemConfig.label,
        yAxisID: "y-axis-2",
        type: "bar",
        backgroundColor: graphLastMonthSlaughter.data.map((item) => {
          return getColor(itemConfig.color, 0.7);
        }),
        borderColor: graphLastMonthSlaughter.data.map((item) => {
          return getColor(itemConfig.color, 0.8);
        }),
        hoverBackgroundColor: graphLastMonthSlaughter.data.map((item) => {
          return getColor(itemConfig.color, 0.9);
        }),
        hoverBorderColor: graphLastMonthSlaughter.data.map((item) => {
          return getColor(itemConfig.color, 1);
        }),
        borderWidth: 1,
        data: graphLastMonthSlaughter.data.map((item) => {
          return key === "legCorns"
            ? item[key].toFixed(0)
            : item[arrayKeys[1]].toFixed(0);
        }),
      });
    });
    return response;
  }, [graphLastMonthSlaughter]);

  const getReportlabelsSlaughter = useCallback(
    () =>
      graphLastMonthSlaughter.data.map((item) => {
        return [
          `Semana ${item.week} / ${item.year} `,
          `De ${convertDateToString(
            item.startOfWeek
          )} até ${convertDateToString(item.endOfWeek)}`,
          `${item.totalBirds} Aves Abatidas`,
        ];
      }),
    [graphLastMonthSlaughter]
  );

  const uploadConsolidatedData = useCallback(async () => {
    setUploading(true);
    const response = await Swal.fire({
      title: "Upload Consolidado",
      text: "Deseja confirmar upload de dados",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#0451e8",
      cancelButtonColor: "#d33",
      confirmButtonText: "Sim, Enviar Dados!",
      showLoaderOnConfirm: true,
      preConfirm: async () => {
        const rows = await readXlsxFile(consolidatedInput.files[0]).catch(
          (err) => {
            return err;
          }
        );
        return await api
          .post(`broilerconsolidated/upload/${customer._id}`, rows)
          .catch((e) => {
            console.log(e);
            return {
              err: true,
              row: e.response?.data?.row,
              col: e.response?.data?.col,
            };
          });
      },
    });

    if (response.value) {
      Swal.fire(
        "Upload Dados",
        response.value.err
          ? `Erro ao inserir célula ${response.value.col}${response.value.row}!`
          : "Dados enviados com sucesso!",
        response.value.err ? "error" : "success"
      );
      if (!response.value.err) {
        setUpdateList(true);
        setConsolidatedInput({ files: [], fileName: "", value: "" });
      }
      setUploading(false);
    }
  }, [consolidatedInput.files, customer._id, setUpdateList]);

  const uploadConsolidatedSlaughterData = useCallback(async () => {
    setUploading(true);
    const response = await Swal.fire({
      title: "Upload Consolidado Abates",
      text: "Deseja confirmar upload de dados",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#0451e8",
      cancelButtonColor: "#d33",
      confirmButtonText: "Sim, Enviar Dados!",
      showLoaderOnConfirm: true,
      preConfirm: async () => {
        const rows = await readXlsxFile(
          consolidatedSlaughterInput.files[0]
        ).catch((err) => {
          return err;
        });
        return await api
          .post(`broilerconsolidatedslaughter/upload/${customer._id}`, rows)
          .catch((e) => {
            console.log(e);
            return {
              err: true,
              row: e.response?.data?.row,
              col: e.response?.data?.col,
            };
          });
      },
    });

    if (response.value) {
      Swal.fire(
        "Upload Dados",
        response.value.err
          ? `Erro ao inserir célula ${response.value.col}${response.value.row}!`
          : "Dados enviados com sucesso!",
        response.value.err ? "error" : "success"
      );
      if (!response.value.err) {
        setUpdateList(true);
        setConsolidatedSlaughterInput({ files: [], fileName: "", value: "" });
      }
      setUploading(false);
    }
  }, [consolidatedSlaughterInput.files, customer._id, setUpdateList]);

  useEffect(() => {
    const loadCustomer = async () => {
      setLoading(true);
      const response = await api
        .get(`connect/customers/${props.match.params.id}`)
        .catch(() => ({ error: true }));
      if (response.error) {
        setLoading(false);
        return Swal.fire(
          "Editar Cliente",
          "Não foi possível buscar o Cliente",
          "error"
        );
      }
      setCustomer(response.data.customer);
      setGraphLastMonth(response.data.graphLastMonth);
      setGraphLastMonthSlaughter(response.data.graphLastMonthSlaughter);
      setHistoricBroiler(response.data.broiler);
      setHistoricHeavyBroiler(response.data.heavyBroiler);
      setConsolidated(response.data.lastConsolidated);
      setConsolidatedSlaughter(response.data.lastConsolidatedSlaughter);
      setLastNecropsies(response.data.lastNecropsies);
      setLastSlaughters(response.data.lastSlaughter);
      setLastChecklistSlaughters(response.data.lastChecklistSlaughter);
      setLastPreNecropsies(response.data.lastPreNecropsies);
      setRandomCore(response.data.randomCore);
      setLoading(false);
    };
    if (getCustomer || updateList) {
      setGetCustomer(false);
      setUpdateList(false);
      loadCustomer();
    }
  }, [customer, props.match.params.id, getCustomer, updateList, setUpdateList]);

  return (
    <>
      <ViewLoader isLoading={loading} />
      {!loading && (
        <>
          <SubHeader
            {...props}
            title={customer.name}
            breadcrumbs={[
              {
                label: "Connect",
              },
              {
                label: "Clientes",
                to: "/customers/1/30/index/{}",
              },
              {
                label: customer.name,
              },
            ]}
            icon={<Icons type="customer" size="24" color="#fff" />}
            otherButtons={
              roles?.indexOf("cliente") < 0
                ? [
                    customer.megg ? (
                      <Image
                        src="https://zenbytes-public.sfo2.digitaloceanspaces.com/mcassab/logo_megg_small.png"
                        style={{ height: 45 }}
                      />
                    ) : null,
                    <Button
                      type="button"
                      bg={props.match.params.tab !== "4" ? "opacity" : "white"}
                      color={
                        props.match.params.tab !== "4" ? "white" : "default"
                      }
                      border={
                        props.match.params.tab !== "4" ? "opacity" : "default"
                      }
                      to={`/customer/${customer._id}/${4}`}
                    >
                      Dados do Cliente
                    </Button>,
                  ]
                : null
            }
          />
          {customer.megg ? (
            <>
              <StepButtonsMegg
                tab={props.match.params.tab}
                customer={customer}
              />
              {!props.match.params.tab || props.match.params.tab === "0" ? (
                <Production
                  {...{
                    historicMegg,
                    customer,
                    location: props.location,
                    match: props.match,
                    setHistoricMegg,
                    lastPreNecropsies,
                  }}
                />
              ) : null}
              {props.match.params.tab === "1" ? (
                <CreateRecreate
                  {...{
                    historicMegg,
                    customer,
                    location: props.location,
                    match: props.match,
                    setHistoricMegg,
                    lastNecropsies,
                  }}
                />
              ) : null}
            </>
          ) : (
            <>
              <StepButtons tab={props.match.params.tab} customer={customer} />
              {!props.match.params.tab || props.match.params.tab === "0" ? (
                <Broiler
                  {...{
                    consolidatedInput,
                    setConsolidatedInput,
                    uploading,
                    uploadConsolidatedData,
                    historicBroiler,
                    graphLastMonth,
                    getReportData,
                    getReportlabels,
                    consolidated,
                    lastNecropsies,
                    customer,
                    location: props.location,
                    match: props.match,
                  }}
                />
              ) : null}

              {props.match.params.tab === "1" && (
                <HeavyBroiler
                  {...{
                    historicHeavyBroiler,
                    customer,
                    location: props.location,
                    lastPreNecropsies,
                    randomCore,
                  }}
                />
              )}
              {props.match.params.tab === "2" && (
                <Slaughter
                  {...{
                    uploading,
                    graphLastMonthSlaughter,
                    customer,
                    location: props.location,
                    lastSlaughters,
                    lastChecklistSlaughters,
                    uploadConsolidatedSlaughterData,
                    consolidatedSlaughterInput,
                    consolidatedSlaughter,
                    setConsolidatedSlaughterInput,
                    getReportDataSlaughter,
                    getReportlabelsSlaughter,
                    match: props.match,
                    historicBroiler,
                  }}
                />
              )}
            </>
          )}

          {props.match.params.tab === "4" && (
            <Contact
              {...props}
              loading={loading}
              customer={customer}
              setCustomer={setCustomer}
              setGetCustomer={setGetCustomer}
            />
          )}
        </>
      )}
    </>
  );
}
