import { Container } from "./style";

export function AnimatedButton({ title, to, onClick, text, icon, active }) {
  return (
    <Container active={active} to={to} onClick={onClick}>
      {icon}
      <div>
        <h1>{title}</h1>
        <span>{text}</span>
      </div>
    </Container>
  );
}
