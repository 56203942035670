import { useState, useEffect, useCallback } from "react";
import domtoimage from "dom-to-image";
import { saveAs } from "file-saver";
import dateFormat from "dateformat";
import ReactLoading from "react-loading";
import Swal from "sweetalert2";
import Compress from "compress.js";
import { imgPreview } from "utils/globalFunctions";
import { Input } from "components/Input";
import { api } from "services/api";
import { Modal } from "components/Modal";
import { Icons } from "components/Icons";
import { Button } from "components/Button";
import { Card, CardBody, CardHeader, Col, Group, Row } from "styles";
import { Body, Header, TableContent } from "components/Table/style";

const Export = (props) => {
  const compress = new Compress();
  const [loading, setLoading] = useState(false);
  const [input, setInput] = useState({
    img: {
      files: [],
    },
    visitDate: "",
    conclusion: "",
  });

  const [checkGraph, setCheckGraph] = useState({
    graph: true,
    graphScore: true,
    consolidated: true,
    score: true,
    injury: true,
    historic: true,
    historicPerPeriod: true,
    scoreGPD: true,
  });

  const [checkGraphComment, setCheckGraphComment] = useState({
    graph: "",
    graphScore: "",
    consolidated: "",
    score: "",
    injury: "",
    historic: "",
    historicPerPeriod: "",
    scoreGPD: "",
  });

  const [injury, setInjury] = useState([]);

  const removeItem = useCallback((index) => {
    setInput((prevState) => ({
      img: {
        files: Array.from(prevState.img.files).filter((item, i) => index !== i),
      },
    }));
  }, []);

  const getImages = () => {
    const response = [];
    for (let i = 0; i < input.img.files.length; i++) {
      const file = input.img.files[i];
      response.push(
        <tr key={file.name}>
          <td>
            <img style={{ maxWidth: "100%" }} src={imgPreview(file)} alt="" />
          </td>
          <td>
            <span>{file.name}</span>
            <br></br>
            <Group style={{ width: "100%" }}>
              <Input
                type="inputOnly"
                onChange={(e) => {
                  const newState = [...injury];
                  newState[i] = e.target.value;
                  setInjury(newState);
                }}
                value={injury[i]}
                disabled={loading}
                placeholder="Digite Lesão"
              />
              <Button
                bg="danger"
                border="danger"
                type="button"
                color="white"
                onClick={() => removeItem(i)}
              >
                <i className="fa fa-trash"></i>
              </Button>
            </Group>
          </td>
        </tr>
      );
    }
    return response;
  };

  async function resizeImageFn(file) {
    const resizedImage = await compress.compress([file], {
      size: 2, // the max size in MB, defaults to 2MB
      quality: 1, // the quality of the image, max is 1,
      maxWidth: 300, // the max width of the output image, defaults to 1920px
      maxHeight: 300, // the max height of the output image, defaults to 1920px
      resize: true, // defaults to true, set false if you do not want to resize the image width and height
    });
    const img = resizedImage[0];
    const base64str = img.data;
    const imgExt = img.ext;
    const resizedFile = Compress.convertBase64ToFile(base64str, imgExt);
    return resizedFile;
  }

  const generateReport = async () => {
    try {
      setLoading(true);
      const formData = new FormData();
      const reportId = new Date().valueOf();
      const content = {
        injury: injury.map((item) => item),
        report: props.responseReport,
        visitDate: input.visitDate,
        conclusion: input.conclusion,
        checkGraph,
        checkGraphComment,
        reportId,
      };
      const blob = new Blob([JSON.stringify(content)], { type: "text/xml" });
      formData.append("data", blob);
      if (checkGraph.graph) {
        for (let i = 0; i < props.responseReport.perPeriod.length; i++) {
          const graphBlob = await domtoimage
            .toPng(document.getElementById(`graph${i}`))
            .catch(() => null);

          if (graphBlob) {
            formData.append(
              `graph${i}`,
              new Blob([JSON.stringify(graphBlob)], { type: "text/xml" })
            );
          }
        }
      }
      if (checkGraph.scoreGPD) {
        const scoreGPDBlob = await domtoimage
          .toPng(document.getElementById(`scoreGPD`))
          .catch(() => null);

        if (scoreGPDBlob) {
          formData.append(
            `scoreGPD`,
            new Blob([JSON.stringify(scoreGPDBlob)], { type: "text/xml" })
          );
        }
      }
      if (checkGraph.score) {
        const scoreBlob = await domtoimage
          .toPng(document.getElementById("score"))
          .catch(() => null);
        if (scoreBlob) {
          formData.append(
            "score",
            new Blob([JSON.stringify(scoreBlob)], { type: "text/xml" })
          );
        }
      }
      if (checkGraph.graphScore) {
        for (let i = 0; i < props.responseReport.perPeriod.length; i++) {
          const graphScoreBlob = await domtoimage
            .toPng(document.getElementById(`graphScore${i}`))
            .catch(() => null);
          if (graphScoreBlob) {
            formData.append(
              `graphScore${i}`,
              new Blob([JSON.stringify(graphScoreBlob)], { type: "text/xml" })
            );
          }
        }
      }
      if (checkGraph.injury) {
        const injuryBlob = await domtoimage
          .toPng(document.getElementById("injury"))
          .catch(() => null);
        if (injuryBlob) {
          formData.append(
            "injury",
            new Blob([JSON.stringify(injuryBlob)], { type: "text/xml" })
          );
        }
      }

      if (checkGraph.historic) {
        const historicBlob = await domtoimage
          .toPng(document.getElementById("historic"))
          .catch(() => null);
        if (historicBlob) {
          formData.append(
            "historic",
            new Blob([JSON.stringify(historicBlob)], { type: "text/xml" })
          );
        }
      }

      if (checkGraph.consolidated) {
        const consolidatedBlob = await domtoimage
          .toPng(document.getElementById("consolidated"))
          .catch(() => null);
        if (consolidatedBlob) {
          formData.append(
            "consolidated",
            new Blob([JSON.stringify(consolidatedBlob)], { type: "text/xml" })
          );
        }
      }
      if (checkGraph.historicPerPeriod) {
        for (let i = 0; i < props.broilerList.length; i++) {
          const historicPerPeriodBlob = await domtoimage
            .toPng(document.getElementById("historicPerPeriod-" + i))
            .catch(() => null);
          if (historicPerPeriodBlob) {
            formData.append(
              "historicPerPeriod-" + i,
              new Blob([JSON.stringify(historicPerPeriodBlob)], {
                type: "text/xml",
              })
            );
          }
        }
      }

      for (let i = 0; i < input.img.files.length; i++) {
        const file = await resizeImageFn(input.img.files[i]);
        formData.append("file", file, input.img.files[i].name);
      }

      const config = {
        responseType: "arraybuffer",
        headers: {
          "content-type": "multipart/form-data",
          Accept: "application/pdf",
        },
      };

      const responseSave = await Swal.fire({
        title: "Salvar Relatório",
        text: "Deseja salvar o relatório no banco de dados?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#0451e8",
        cancelButtonColor: "#d33",
        confirmButtonText: "Sim, salvar!",
        cancelButtonText: "Não, apenas exportar!",
        showLoaderOnConfirm: true,
        preConfirm: async () =>
          await api
            .post("/connect/reportbroiler", {
              url: props.location.pathname,
              customer: props.customer,
              reportId,
              data: {
                input,
                checkGraph,
                injury,
                checkGraphComment,
              },
            })
            .catch((err) => ({
              err: true,
              data: { message: err.response.data.message },
            })),
      });
      if (responseSave.err) {
        Swal.fire("Salvar Relatório", "Erro ao salvar relatório!", "error");
      }
      const response = await api
        .post(
          `/connect/broiler/export/pdf?lenght=${props.responseReport.perPeriod.length}`,
          formData,
          config
        )
        .catch(() => ({ error: true }));
      if (response.error) {
        setLoading(false);
        return Swal.fire("Exportar Relatório", "Erro ao exportar !", "error");
      }
      const blobPDF = new Blob([response.data], {
        type: "application/pdf",
      });

      saveAs(
        blobPDF,
        `Relatório de visita - ${dateFormat(new Date(), "isoDateTime")}.pdf`
      );
      const formDataPDF = new FormData();
      formDataPDF.append(
        "file",
        blobPDF,
        `report-broiler-${props.customer}-${reportId}.pdf`
      );
      await api
        .post("/fileUpload", formDataPDF, config)
        .catch(() => ({ error: true }));
      setLoading(false);
    } catch (e) {
      Swal.fire("Exportar Relatório", "Erro ao exportar !", "error");
      setLoading(false);
    }
  };

  useEffect(() => {
    const getReportData = async () => {
      const response = await api.get(`connect/reportbroiler/${props.id}`);
      const { input, checkGraph, injury, checkGraphComment } =
        response.data.data;
      setInput({
        ...input,
        img: {
          files: [],
        },
      });
      setCheckGraph(checkGraph);
      setInjury(injury);
      setCheckGraphComment(checkGraphComment);
      setLoading(false);
    };
    if (props.id) {
      setLoading(true);
      getReportData();
    }
  }, [props.id]);

  return (
    <Modal
      show={props.show}
      close={props.onHide}
      header={
        <>
          <Icons type="lineChart" color="#009870" size={30} />
          <h2>Exportar Relatório</h2>
        </>
      }
      footer={
        <Button
          bg="default"
          border="default"
          color="white"
          onClick={generateReport}
          disabled={loading}
        >
          {loading ? (
            <div style={{ display: "flex" }}>
              <ReactLoading
                type="spin"
                color="#ffffff"
                height={19}
                width={19}
              />
              &nbsp;
              <span>Aguarde, isso pode levar alguns minutos </span>
            </div>
          ) : (
            <>
              <i className="fas fa-cogs" />
              &nbsp; Gerar PDF
            </>
          )}
        </Button>
      }
    >
      <Row>
        <Col>
          <Input
            label="Datas das Visitas"
            type="input"
            item={input}
            setItem={setInput}
            multiple={true}
            params="visitDate"
            disabled={loading}
            placeholder="Digite as datas das visitas técnicas"
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <label>Selecione os relatórios</label>
        </Col>
      </Row>
      <Row>
        <Col>
          <Input
            label="Lesões por Total de Aves "
            type="checkbox"
            item={checkGraph}
            setItem={setCheckGraph}
            params="injury"
            disabled={loading}
          />
          <Input
            style={{ marginTop: 10 }}
            type="textAreaOnly"
            item={checkGraphComment}
            setItem={setCheckGraphComment}
            params="injury"
            placeholder="Observações"
          />
        </Col>
        <Col>
          <Input
            label="Lesões por Score (gráfico)"
            type="checkbox"
            item={checkGraph}
            setItem={setCheckGraph}
            params="graphScore"
            disabled={loading}
          />
          <Input
            style={{ marginTop: 10 }}
            type="textAreaOnly"
            item={checkGraphComment}
            setItem={setCheckGraphComment}
            params="graphScore"
            placeholder="Observações"
          />
        </Col>
        <Col>
          <Input
            label="Programa em Andamento"
            type="checkbox"
            item={checkGraph}
            setItem={setCheckGraph}
            params="historic"
            disabled={loading}
          />
          <Input
            style={{ marginTop: 10 }}
            type="textAreaOnly"
            item={checkGraphComment}
            setItem={setCheckGraphComment}
            params="historic"
            placeholder="Observações"
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <Input
            label="% Lesões por Score(gráfico)"
            type="checkbox"
            item={checkGraph}
            setItem={setCheckGraph}
            params="graph"
            disabled={loading}
          />
          <Input
            style={{ marginTop: 10 }}
            type="textAreaOnly"
            item={checkGraphComment}
            setItem={setCheckGraphComment}
            params="graph"
            placeholder="Observações"
          />
        </Col>
        <Col>
          <Input
            label="Score por Lesão"
            type="checkbox"
            item={checkGraph}
            setItem={setCheckGraph}
            params="score"
            disabled={loading}
          />
          <Input
            style={{ marginTop: 10 }}
            type="textAreaOnly"
            item={checkGraphComment}
            setItem={setCheckGraphComment}
            params="score"
            placeholder="Observações"
          />
        </Col>
        <Col>
          <Input
            label="Resultado Consolidado"
            type="checkbox"
            item={checkGraph}
            setItem={setCheckGraph}
            params="consolidated"
            disabled={loading}
          />
          <Input
            style={{ marginTop: 10 }}
            type="textAreaOnly"
            item={checkGraphComment}
            setItem={setCheckGraphComment}
            params="consolidated"
            placeholder="Observações"
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <Input
            label="Desenvolvimento Semana"
            type="checkbox"
            item={checkGraph}
            setItem={setCheckGraph}
            params="scoreGPD"
            disabled={loading}
          />
          <Input
            style={{ marginTop: 10 }}
            type="textAreaOnly"
            item={checkGraphComment}
            setItem={setCheckGraphComment}
            params="scoreGPD"
            placeholder="Observações"
          />
        </Col>
        <Col></Col>
        <Col></Col>
      </Row>
      {props.broilerList[0] && (
        <Row>
          <Col>
            <Input
              label="Programa por Período"
              type="checkbox"
              item={checkGraph}
              setItem={setCheckGraph}
              params="historicPerPeriod"
              disabled={loading}
            />
            <Input
              style={{ marginTop: 10 }}
              type="textAreaOnly"
              item={checkGraphComment}
              setItem={setCheckGraphComment}
              params="historicPerPeriod"
              placeholder="Observações"
            />
          </Col>
        </Row>
      )}

      <Row>
        <Col>
          <Input
            label="Conclusão"
            type="textarea"
            item={input}
            setItem={setInput}
            multiple={true}
            params="conclusion"
            disabled={loading}
            placeholder="Digite a Conclusão"
            rows={5}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <Input
            label="Imagens Necrópsia"
            type="inputFile"
            item={input}
            setItem={setInput}
            multiple={true}
            params="img"
            accept="image/*"
            disabled={loading}
            placeholder="Selecione as fotos tiradas nas visitas"
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <Card>
            <CardHeader>Imagens Selecionadas</CardHeader>
            <CardBody>
              <TableContent>
                <Header>
                  <tr>
                    <th style={{ width: "200px" }}>Imagem</th>
                    <th>Lesão</th>
                  </tr>
                </Header>
                <Body>{getImages()}</Body>
              </TableContent>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Modal>
  );
};

export default Export;
