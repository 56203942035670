import { useState, useEffect, useContext, useMemo } from "react";
import Swal from "sweetalert2";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import ReactLoading from "react-loading";
import { ScoreTotal } from "./ScoreTotal";
import { Customer } from "./Customer";
import { Data } from "./Data";
import { api } from "services/api";
import { Modal } from "components/Modal";
import { Container } from "./style";
import {
  convertStringToDate,
  convertDateToString,
} from "utils/globalFunctions";
import UpdateList from "contexts/updateList";
import { Icons } from "components/Icons";
import { Button } from "components/Button";
import { VentralInspection } from "./VentralInspection";
import { DorsalInspection } from "./DorsalInspection";
import { InternalInspection } from "./InternalInspection";
import { LegCorns } from "./LegCorns";

export function CreateEdit(props) {
  const initialConfig = useMemo(
    () => ({
      "ventralInspection-skinBreakage": {
        label: "Rompimento de pele",
        options: [],
      },
      "ventralInspection-chestSkinBurn": {
        label: "Queimadura na pele do peito",
        options: [],
      },
      "ventralInspection-celluliteBelowChest": {
        label: "Celulite no músculo do peito",
        options: [],
      },
      "ventralInspection-chestSkinBleeding": {
        label: "Hemorragia na pele do peito",
        options: [],
      },
      "ventralInspection-bleedingWings": {
        label: "Hemorragia nas asas",
        options: [],
      },
      "ventralInspection-displacementWings": {
        label: "Deslocamento de asa",
        options: [],
      },
      "ventralInspection-fractureWings": {
        label: "Fratura de asas",
        options: [],
      },
      "dorsalInspection-tailSkinBreakage": {
        label: "Rompimento da pele cauda",
        options: [],
      },
      "dorsalInspection-dermatitis": { label: "Dermatite", options: [] },
      "dorsalInspection-scratchedWoundNew": {
        label: "Arranhados/ Ferida Nova",
        options: [],
      },
      "dorsalInspection-scratchedWoundOld": {
        label: "Arranhados/ Ferida Velha",
        options: [],
      },
      "dorsalInspection-bleedingThighs": {
        label: "Hemorragia nas coxas/sobrecoxa",
        options: [],
      },
      "dorsalInspection-thighFracture": {
        label: "Fratura das coxas",
        options: [],
      },
      "dorsalInspection-dorsalMyopathy": {
        label: "Miopatia dorsal",
        options: [],
      },
      "internalInspection-punctateChestHemorrhage": {
        label: "Hemorragia puntiforme no peito",
        options: [],
      },
      "internalInspection-thighsPunctiformHemorrhage": {
        label: "Hemo- Puntiforme coxas/sobrecoxa",
        options: [],
      },
      "internalInspection-whiteStreak": { label: "Estria branca", options: [] },
      "internalInspection-woodChest": { label: "Peito madeira", options: [] },
      "internalInspection-chestSpaghetti": {
        label: "Peito Spaghetti",
        options: [],
      },
      legCorns: { label: "Calo de patas", options: [] },
    }),
    []
  );

  const { setUpdateList } = useContext(UpdateList);

  const [graphs, setGraphs] = useState([]);

  const [total, setTotal] = useState({
    label: "Score do Lote",
    param: "total",
    value: 0,
  });

  const [slaughter, setSlaughter] = useState({
    _id: "",
    customer: props.customer || null,
    evaluationDate: "",
    collectionTime: "",
    collectionShift: "",
    lotDensity: "",
    technicalManager: "",
    integratedProducer: "",
    batchCode: "",
    matrixLot: "",
    batchAge: "",
    numberOfBirdsLot: "",
    averegeBatchWeight: "",
    dailyBreastYieldPercent: "",
    batchUniformtyPercent: "",
    numberOfBirdsMale: 5,
    numberOfBirdsFemale: 5,
    suspensionDate: "",
    fastingHours: "",
    detectedDiseases: "",
    ventralInspection: {
      skinBreakage: {
        light: {
          male: "",
          female: "",
        },
        severe: {
          male: "",
          female: "",
        },
        total: 0,
      },
      chestSkinBurn: {
        light: {
          male: "",
          female: "",
        },
        severe: {
          male: "",
          female: "",
        },
        total: 0,
      },
      celluliteBelowChest: {
        light: {
          male: "",
          female: "",
        },
        severe: {
          male: "",
          female: "",
        },
        total: 0,
      },
      chestSkinBleeding: {
        light: {
          male: "",
          female: "",
        },
        severe: {
          male: "",
          female: "",
        },
        total: 0,
      },
      bleedingWings: {
        light: {
          male: "",
          female: "",
        },
        severe: {
          male: "",
          female: "",
        },
        total: 0,
      },
      displacementWings: {
        light: {
          male: "",
          female: "",
        },
        severe: {
          male: "",
          female: "",
        },
        total: 0,
      },
      fractureWings: {
        light: {
          male: "",
          female: "",
        },
        severe: {
          male: "",
          female: "",
        },
        total: 0,
      },
      total: 0,
    },
    dorsalInspection: {
      tailSkinBreakage: {
        light: {
          male: "",
          female: "",
        },
        severe: {
          male: "",
          female: "",
        },
        total: 0,
      },
      dermatitis: {
        light: {
          male: "",
          female: "",
        },
        severe: {
          male: "",
          female: "",
        },
        total: 0,
      },
      scratchedWoundNew: {
        light: {
          male: "",
          female: "",
        },
        severe: {
          male: "",
          female: "",
        },
        total: 0,
      },
      scratchedWoundOld: {
        light: {
          male: "",
          female: "",
        },
        severe: {
          male: "",
          female: "",
        },
        total: 0,
      },
      bleedingThighs: {
        light: {
          male: "",
          female: "",
        },
        severe: {
          male: "",
          female: "",
        },
        total: 0,
      },
      thighFracture: {
        light: {
          male: "",
          female: "",
        },
        severe: {
          male: "",
          female: "",
        },
        total: 0,
      },
      dorsalMyopathy: {
        light: {
          male: "",
          female: "",
        },
        severe: {
          male: "",
          female: "",
        },
        total: 0,
      },
      total: 0,
    },
    internalInspection: {
      punctateChestHemorrhage: {
        light: {
          male: "",
          female: "",
        },
        severe: {
          male: "",
          female: "",
        },
        total: 0,
      },
      thighsPunctiformHemorrhage: {
        light: {
          male: "",
          female: "",
        },
        severe: {
          male: "",
          female: "",
        },
        total: 0,
      },
      whiteStreak: {
        light: {
          male: "",
          female: "",
        },
        severe: {
          male: "",
          female: "",
        },
        total: 0,
      },
      woodChest: {
        light: {
          male: "",
          female: "",
        },
        severe: {
          male: "",
          female: "",
        },
        total: 0,
      },
      chestSpaghetti: {
        light: {
          male: "",
          female: "",
        },
        severe: {
          male: "",
          female: "",
        },
        total: 0,
      },
      total: 0,
    },
    legCorns: {
      light: {
        male: "",
        female: "",
      },
      severe: {
        male: "",
        female: "",
      },

      total: 0,
    },
    total: 10,
  });

  const [loadHistoric, setLoadHistoric] = useState(!!props.customer);

  const [loading, setLoading] = useState(true);

  const [getSlaughter, setGetSlaughter] = useState(true);

  const [tab, setTab] = useState(0);

  const [config, setConfig] = useState(initialConfig);

  const saveSlaughter = async (e) => {
    const requestSlaughter = JSON.parse(JSON.stringify(slaughter));
    if (requestSlaughter.evaluationDate) {
      requestSlaughter.evaluationDate = convertStringToDate(
        requestSlaughter.evaluationDate
      );
    }
    if (requestSlaughter.suspensionDate) {
      requestSlaughter.suspensionDate = convertStringToDate(
        requestSlaughter.suspensionDate
      );
    }
    requestSlaughter.total = total.value;
    graphs.forEach(({ param, value, params }) => {
      requestSlaughter[param].total = value;
      if (params) {
        params.forEach(
          ({ name, value }) => (requestSlaughter[param][name].total = value)
        );
      }
    });
    if (!requestSlaughter._id) {
      const response = await Swal.fire({
        title: "Salvar Abate",
        text: "Deseja confirmar?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#0451e8",
        cancelButtonColor: "#d33",
        confirmButtonText: "Sim, Salvar!",
        showLoaderOnConfirm: true,
        preConfirm: async () =>
          await api.post("broilerslaughter", requestSlaughter).catch((err) => ({
            err: true,
            data: { message: err.response.data.message },
          })),
      });
      if (response.value) {
        Swal.fire(
          "Salvar Abate",
          response.value.data.message,
          response.value.err ? "error" : "success"
        );
        if (!response.value.err) {
          requestSlaughter._id = response.value.data.id;
          setSlaughter(requestSlaughter);
        }
        setUpdateList(true);
      }
    } else {
      const response = await Swal.fire({
        title: "Editar Abate",
        text: "Deseja confirmar edição?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#0451e8",
        cancelButtonColor: "#d33",
        confirmButtonText: "Sim, Editar!",
        showLoaderOnConfirm: true,
        preConfirm: async () =>
          await api
            .put("broilerslaughter/" + requestSlaughter._id, requestSlaughter)
            .catch((err) => ({
              err: true,
              data: { message: err.response.data.message },
            })),
      });

      if (response.value) {
        Swal.fire(
          "Editar Abate",
          response.value.data.message,
          response.value.err ? "error" : "success"
        );
        setUpdateList(true);
      }
    }
  };

  useEffect(() => {
    const loadSlaughter = async () => {
      setLoading(true);
      const response = await api.get("broilerslaughterconfig");
      if (response.data) {
        const copyConfig = JSON.parse(JSON.stringify(config));
        response.data.forEach((item) => {
          if (copyConfig[item.key]) {
            copyConfig[item.key].options.push({ ...item });
          }
        });
        setConfig(copyConfig);
      }
      if (props.id) {
        const responseSlaughter = await api.get(`broilerslaughter/${props.id}`);
        if (responseSlaughter.data) {
          if (responseSlaughter.data.evaluationDate) {
            responseSlaughter.data.evaluationDate = convertDateToString(
              responseSlaughter.data.evaluationDate
            );
          }
          if (responseSlaughter.data.suspensionDate) {
            responseSlaughter.data.suspensionDate = convertDateToString(
              responseSlaughter.data.suspensionDate
            );
          }
          setSlaughter(JSON.parse(JSON.stringify(responseSlaughter.data)));
          setLoadHistoric(true);
        }
      }

      setLoading(false);
    };
    if (getSlaughter) {
      setGetSlaughter(false);
      loadSlaughter();
    }
  }, [getSlaughter, slaughter, props.id, config]);

  return loading ? (
    <ReactLoading type="spin" color="#009870" height={30} width={30} />
  ) : (
    <Modal
      show={props.show}
      close={() => props.setShow(false)}
      header={
        <>
          <Icons type="slaughter" color="#009870" size={30} />
          <h2>Abate</h2>
        </>
      }
      footer={
        tab === 6 && (
          <Button
            color="white"
            bg="default"
            border="default"
            onClick={saveSlaughter}
          >
            Salvar
          </Button>
        )
      }
    >
      <>
        <Tabs
          value={tab}
          indicatorColor="primary"
          textColor="primary"
          variant="scrollable"
          scrollButtons="on"
          onChange={(e, value) => {
            setTab(value);
          }}
        >
          <Tab label="Cliente" />
          <Tab label="Dados" />
          <Tab label="Inspeção Ventral" />
          <Tab label="Inspeção Dorsal" />
          <Tab label="inspeção Interna" />
          <Tab label="Calo de Patas" />
          <Tab label="Score Total" />
        </Tabs>
        <Container />
        {tab === 0 && (
          <Customer
            slaughter={slaughter}
            setSlaughter={setSlaughter}
            loadHistoric={loadHistoric}
            disableCustomer={props.disableCustomer}
            setLoadHistoric={setLoadHistoric}
          />
        )}

        {tab === 1 && (
          <Data slaughter={slaughter} setSlaughter={setSlaughter} />
        )}
        {tab === 2 && (
          <VentralInspection
            slaughter={slaughter}
            setSlaughter={setSlaughter}
            config={config}
          />
        )}
        {tab === 3 && (
          <DorsalInspection
            slaughter={slaughter}
            setSlaughter={setSlaughter}
            config={config}
          />
        )}
        {tab === 4 && (
          <InternalInspection
            slaughter={slaughter}
            setSlaughter={setSlaughter}
            config={config}
          />
        )}
        {tab === 5 && (
          <LegCorns
            slaughter={slaughter}
            setSlaughter={setSlaughter}
            config={config}
          />
        )}
        {tab === 6 && (
          <ScoreTotal
            slaughter={slaughter}
            graphs={graphs}
            setGraphs={setGraphs}
            total={total}
            setTotal={setTotal}
            config={config}
          />
        )}
      </>
    </Modal>
  );
}
